import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  ethapprove,
  ethdecline,
  pixaapprove,
  pixadecline,
  checkcurrentburnrate,
  checkcurrentrewardrate,
  executeBurn,
  executeReward,
  calculateCurrentRewards,
  checkSpecificRewards,
  checkcurrentpixavoteaddress,
  checkcurrentpixavotereason,
  checkcurrentpixavoteamount,
  checkcurrentpixavotestate,
  checkcurrentethvotestate,
  checkcurrentethvotereason,
  checkcurrentethvoteamount,
  checkcurrentethvoteaddress,
  checkcurrentpixatabs,
  checkcurrentpixavote,
  initiateBurn,
  initiateRewards,
  checktimetorewards,
  checktimetoburn,
  finalizeEth,
  finalizePixa,
  createEthVote,
  createPixaVote,
  claimNFTRewards
} from "./utils/interact.js";

const Minter = (props) => {

  //State variables
  const [specificrewardcontract, setCheckSpecificRewardsContract] = useState("")
  const [specificrewardtokenid, setCheckSpecificRewardtknID] = useState("")
  const [specificrewardsavailable, setCheckSpecificRewards] = useState("");
  const [totalrewardsavailable, setchecktimetorewards] = useState("");
  const [rewardsaddress, setRewardsCheckAddress] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [burnrate, setBurnRate] = useState("");
  const [rewardrate, setRewardRate] = useState(""); 
  const [ethtransfer, setethtransfer] = useState("");
  const [pixatransfer, setpixatransfer] = useState("");
  const [currentburnrate, setcurrentburnrate] = useState("");
  const [currentrewardrate, setcurrentrewardrate] = useState("");
  const [timetorewards, settimetorewards] = useState("");
  const [timetoburn, settimetoburn] = useState("");

  //pixa propose vote
  const [PixaReason, setPixaReason] = useState("");  
  const [PixaAmount, setPixaAmount] = useState("");
  const [PixaAddress, setPixaAddress] = useState("");

  //eth propose vote
  const [EthReason, setEthReason] = useState("");  
  const [EthAmount, setEthAmount] = useState("");
  const [EthAddress, setEthAddress] = useState("");

  //pixa voting
  const [currentpixavotestate, setpixavotestate] = useState("");  
  const [currentpixavotereason, setpixavotereason] = useState("");
  const [currentpixavoteamount, setpixavoteamount] = useState("");
  const [currentpixavoteaddress, setpixavoteaddress] = useState("");
  const [currentpixavotetabs, setpixavotetabs] = useState("");
  const [currentpixavotecount, setpixavote] = useState("");
  const [pixafinalize, setPixaFinalize] = useState("");

  //eth voting
  const [currentethvotestate, setethvotestate] = useState("");  
  const [currentethvotereason, setethvotereason] = useState("");
  const [currentethvoteamount, setethvoteamount] = useState("");
  const [currentethvoteaddress, setethvoteaddress] = useState("");
  const [currentethvotetabs, setethvotetabs] = useState("");
  const [currentethvotecount, setethvote] = useState("");
  const [ethfinalize, setEthFinalize] = useState("");


  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
 

  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    setBurnRate(10);
    setRewardRate(10);
    addWalletListener(); 
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    const { currentburnrate } = await checkcurrentburnrate();
    setcurrentburnrate(currentburnrate); 
    const { currentrewardrate } = await checkcurrentrewardrate();
    setcurrentrewardrate(currentrewardrate); 
    const { timetorewards} = await checktimetorewards();
    settimetorewards(timetorewards); 
    const { timetoburn} = await checktimetoburn();
    settimetoburn(timetoburn); 

    // pixa voting
    const { currentpixavotestate } = await checkcurrentpixavotestate();
    setpixavotestate(currentpixavotestate); 


    if (currentpixavotestate) {
    const { currentpixavotereason } = await checkcurrentpixavotereason();
    setpixavotereason(currentpixavotereason); 
    const { currentpixavoteamount } = await checkcurrentpixavoteamount();
    setpixavoteamount(currentpixavoteamount); 
    const { currentpixavoteaddress } = await checkcurrentpixavoteaddress();
    setpixavoteaddress(currentpixavoteaddress);
    const { currentpixavotecount } = await checkcurrentpixavote();
    setpixavote(currentpixavotecount); 
    const { currentpixavotetabs } = await checkcurrentpixatabs();
    setpixavotetabs(currentpixavotetabs); 

    } 
    else {
    setpixavotereason("No current vote"); 
    setpixavoteamount("-"); 
    setpixavoteaddress("-"); 
    setpixavotetabs("-");
    setpixavote("-");
    }
    // etj voting
    const { currentethvotestate } = await checkcurrentethvotestate();
    setethvotestate(currentethvotestate); 


    if (currentethvotestate) {
    const { currentethvotereason } = await checkcurrentethvotereason();
    setethvotereason(currentethvotereason); 
    const { currentethvoteamount } = await checkcurrentethvoteamount();
    setethvoteamount(currentethvoteamount); 
    const { currentethvoteaddress } = await checkcurrentethvoteaddress();
    setethvoteaddress(currentethvoteaddress);
    } 
    else {
    setethvotereason("No current vote"); 
    setethvoteamount("-"); 
    setethvoteaddress("-"); 
    setethvotetabs("-");
    setethvote("-");

    }
  };

  const onCalculateRewardsPressed = async () => { //TODO: implement
      setchecktimetorewards("Calculating please wait..."); 
      const {totalrewardsavailable} = await calculateCurrentRewards(rewardsaddress);
      setchecktimetorewards([totalrewardsavailable, ' $PIXA']);
  };


  

  const onCheckSpecificRewardspressed = async () => { //TODO: implement
      setCheckSpecificRewards("Calculating please wait...")
      const {specificrewardsavailable} = await checkSpecificRewards(specificrewardcontract,specificrewardtokenid);
      setCheckSpecificRewards(specificrewardsavailable)
  };

  const onBurnPressed = async () => { //TODO: implement
      executeBurn(burnrate);
  };

  const onRewardPressed = async () => { //TODO: implement
      executeReward(rewardrate);
  };

  const onEthVotePressed = async () => { //TODO: implement
      createEthVote(EthAddress, EthAmount, EthReason);
  };

  const onPixaVotePressed = async () => { //TODO: implement
      createPixaVote(PixaAddress, PixaAmount, PixaReason);
  };

  const onInitiateBurnPressed = async () => { //TODO: implement
      initiateBurn();
  };

  const onInitiateRewardsPressed = async () => { //TODO: implement
      initiateRewards();
  };



  const onClaimNFTRewardsPressed = async () => { //TODO: implement
      claimNFTRewards();
  };
  //const onBurnPressed = async () => { //TODO: implement
   //   const { currentburnrate } = await executeburnrate(name);  
  //};

  //const onRewardPressed = async () => { //TODO: implement
  //    const { currentrewardrate } = await executerewardrate(name);  
  //};


  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
    <div className="Minter">


      <h1 id="title">WizarDAO Interface</h1>
      <br></br>      
      <h4 id="subheading"> Main Pixalyfe site:</h4>
      <div id = "webbutton">
      <form id="webbutton" action="https://pixalyfenft.com">
        <input id="webbutton" type="submit" value="PixaLyfe" />
      </form> 
      </div>
      <br></br>  
      <h4 id="subheading"> Claim your WizarDAO NFT:</h4>     
      <div id = "webbutton">
      <form id="webbutton" action="https://claim.wizardao.eth.limo">
        <input id="webbutton" type="submit" value="Claim" />
      </form> 
      </div>
      <h4> Welcome to the WizarDAO interface. Owners with WizarDAO tokens are able to influence various aspects of the PixaLyfe NFT ecosystem through commands entered through this webpage. Owners of PixaLyfe NFTs can also claim $PIXA utility token rewards.</h4>  
      <h4 id="subheading">Disclaimer: PixaLyfe is not liable for your use of this interface, $PIXA, or it's NFT collections. Use at your own risk. We do not guarantee the information provided on this page is accurate, but we guarantee PixaLyfe intends it to be accurate. The PixaLyfe NFT ecosystem is based around collecting one-of-a-kind digital collectibles and interacting with them in unique ways. These are not investments, and we will never offer you financial advice. Prior to interacting with this site, read the terms and conditions you agree to by using PixaLyfe websites and platforms: pixalyfenft.com/terms (also can be found at the bottom of this page)</h4>   
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
      <h4 id="subheading">Note: Click "connect wallet" button above to update contract information in real-time (ie. current burn rate, rewards rate, etc.)</h4>     
      <br></br>  
      <br></br>  
      <br></br>  
<h4> _________</h4>
      <br></br>  
      <br></br>  
      <br></br>        
      <h2 id= "pixarewardz">Claim weekly $PIXA rewards! </h2>      
      <h4>You can only claim rewards if you own at least 1 PixaLyfe NFT. $PIXA is a utility token meant to interact with your PixaLyfe NFTs. $PIXA has no value. You can only claim once per week. Once claimed, your NFTs weekly clock is reset. Claims follow the NFTs. Claim amounts will accumulate for up to 1 month, then will be capped if unclaimed.</h4>     
      <button id="zeropercentwitchbutton" onClick={onClaimNFTRewardsPressed}>
        Claim Rewards!
      </button>      

      <h4> Check your accumulated $PIXA rewards that are ready to claim! Enter the ethereum address you want to check: </h4>


      <input id="proposals"
          type="string"
          placeholder="address"
          onChange={(event) => setRewardsCheckAddress(event.target.value)}
        />
      <button id="ethfinalize" onClick={onCalculateRewardsPressed}>
        Check Rewards!
      </button> 
      <h4 id="subheading">Your current claimable rewards: {totalrewardsavailable}</h4>      
      
      <h4> Check the accumulated $PIXA rewards on a specific PixaLyfe NFT. Select the NFT collection and specific tokenID for the NFT (check under "details" on OpenSea"). </h4>



      <select name="NFTcollections" id="nftcollections" onChange={(event) => setCheckSpecificRewardsContract(event.target.value)}>
          <option value="select">-Select Collection-</option>       
          <option value="wizards">PixaWizards</option>
          <option value="witches">PixaWitches</option>
          <option value="wyverns">PixaWyverns</option>
          <option value="pixale">PixaLE</option>
          <option value="relics">Relics of the PixaVerse</option>
          <option value="wizardao">WizarDAO</option>
      </select>
      <br></br>  
      <input id="checktokenid"
          type="number"
          placeholder="token ID"
          onChange={(event) => setCheckSpecificRewardtknID(event.target.value)}
        />
      <br></br>  
      <button id="ethfinalize" onClick={onCheckSpecificRewardspressed}>
        Check Token Rewards!
      </button> 
      <h4 id="subheading"> Current claimable rewards on this NFT: {specificrewardsavailable} $PIXA </h4>


      <br></br>  
      <br></br>  
      <br></br>  
<h4> _________</h4>
      <br></br>  
      <br></br>  
      <br></br>  

      <h2 id= "pixaburn">Adjust $PIXA burn rate: </h2>
      <h4>Enter a number between -10 and 10. This will be your contribution (per WizarDAO token) to increasing or decreasing the weekly burn rate. You can adjust this once per week. </h4>
      <h4 id="subheading">Current burn rate: {currentburnrate} $PIXA / week</h4>
      <input id="burnrateentry"
          type="number"
          placeholder="#"
          onChange={(event) => setBurnRate(+event.target.value + 10)}
        />
      <button id="zeropercentwitchbutton" onClick={onBurnPressed}>
        Execute
      </button>
      <h4> Once per week, any WizarDAO holder can initiate the $PIXA burn. The user who initiates the burn will receive a $PIXA reward airdrop.</h4>     
      <button id="ethfinalize" onClick={onInitiateBurnPressed}>
        Initiate Burn
      </button> 

      <h4 id="subheading"> $PIXA burn next available on: {timetoburn}</h4>



      <br></br>  
      <br></br>  
      <br></br>  
<h4> _________</h4>
      <br></br>  
      <br></br>  
      <br></br>  

      <h2 id="pixareward">Adjust NFT reward allocation: </h2>
      <h4>Enter a number between -10 and 10. This will be your contribution (per WizarDAO token) to increasing or decreasing the weekly reward rate to be divided between all PixaLyfe NFT collections. You can adjust this once per week. </h4>

      <h4 id="subheading">Current reward rate: {currentrewardrate} $PIXA / week</h4>
      <input id="burnrateentry"
          type="number"
          placeholder="#"
          onChange={(event) => setRewardRate(+event.target.value + 10)}
        />
      <button id="zeropercentwitchbutton" onClick={onRewardPressed}>
        Execute
      </button>


      <h4> Once per week, any WizarDAO holder can initiate the Rewards transfer. The user who initiates this transfer will receive a $PIXA reward airdrop.</h4>     
      <button id="ethfinalize" onClick={onInitiateRewardsPressed}>
        Initiate Rewards
      </button> 
      <h4 id="subheading"> Rewards transfer next available on: {timetorewards}</h4>


      <br></br>  
      <br></br>  
      <br></br>  
<h4> _________</h4>
      <br></br>  
      <br></br>  
      <br></br>  

     
      <h2  id="ethtransfer">Current ETH transfer vote:</h2>
      <h4>Proposal: {currentethvotereason} </h4>
      <h4>Amount: {currentethvoteamount} </h4>
      <h4>Transfer to: {currentethvoteaddress} </h4>
      <h4>Current result: {currentethvotetabs} (votes: {currentethvotecount})</h4>



      <button id="ethapprove" onClick={ethapprove}>
        Approve +
      </button>
      <button id="ethdecline" onClick={ethdecline}>
        Decline -
      </button> 
      <br></br> 

      <h4>The outcome of the vote can be finalized once >100 votes have been cast and 1 week passes from the vote proposal. The user who finalizes the vote will receive a $PIXA reward airdrop.</h4>
   
      <button id="ethfinalize" onClick={finalizeEth}>
        Finalize
      </button> 

      <h2 id="ethtransfer">Propose ETH transfer vote:</h2>
      <h4>Cost: 0.1 ETH to propose transfer. Returned if proposal is successful. Can only propose a vote if there is no current ETH transfer vote. Must be an integer(whole) number of ETH to be transferred.</h4>
      <input id="proposals"
          type="text"
          placeholder="Reason for the transfer (1 sentence)"
          onChange={(event) => setEthReason(event.target.value)}
      />
      <br></br>  

      <input id="proposals"
          type="number"
          placeholder="Amount in ETH (i.e. 0.4)"
          onChange={(event) => setEthAmount(+event.target.value)}
      />
      <br></br>  
      <input id="proposals"
          type="text"
          placeholder="Recipient ethereum address"
          onChange={(event) => setEthAddress(event.target.value)}
        />

      <br></br>  
      <button id="zeropercentwitchbutton" onClick={onEthVotePressed}>
        Propose
      </button>




      <br></br>  
      <br></br>  
      <br></br>  
<h4> _________</h4>
      <br></br>  
      <br></br>  
      <br></br>  









      <h2  id="pixatransfer">Current $PIXA transfer vote:</h2>

      <h4>Proposal: {currentpixavotereason} </h4>
      <h4>Amount: {currentpixavoteamount} </h4>
      <h4>Transfer to: {currentpixavoteaddress} </h4>
      <h4>Current result: {currentpixavotetabs} (votes: {currentpixavotecount})</h4>

      <button id="pixaapprove" onClick={pixaapprove}>
        Approve +
      </button>
      <button id="pixadecline" onClick={pixadecline}>
        Decline -
      </button> 

      <br></br>        

      <h4>The outcome of the vote can be finalized once >100 votes have been cast and 1 week passes from the vote proposal. The user who finalizes the vote will receive a $PIXA reward airdrop.</h4>
      <button id="pixafinalize" onClick={finalizePixa}>
        Finalize
      </button> 

      <br></br>  
      <br></br>  
      <h2  id="pixatransfer">Propose $PIXA transfer vote:</h2>
      <h4>Cost: 0.1 ETH to propose transfer. Returned if proposal is successful. Can only propose a vote if there is no current $PIXA transfer vote. Must be an integer(whole) number of ETH to be transferred. </h4>
      <input id="proposals"
          type="string"
          placeholder="Reason for the transfer (1 sentence)"
          onChange={(event) => setPixaReason(event.target.value)}
      />
      <br></br>  

      <input id="proposals"
          type="number"
          placeholder="Amount in $PIXA (i.e. 3000)"
          onChange={(event) => setPixaAmount(+event.target.value)}
      />
      <br></br>  
      <input id="proposals"
          type="string"
          placeholder="Recipient ethereum address"
          onChange={(event) => setPixaAddress(event.target.value)}
        />
      <br></br>  
      <button id="zeropercentwitchbutton" onClick={onPixaVotePressed}>
        Propose
      </button>

      <br></br>  
      <br></br>  
      <br></br>  
<h4> _________</h4>
      <br></br>  
      <br></br>  
      <br></br>  

      <h5> Terms and Conditions (applies to all PixaLyfe sites): Your purchase of PixaLyfe NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from PixaLyfe is meant for entertainment purposes only. PixaLyfe makes no warranties regarding future value, express or implied, to the public or to buyers. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. PixaLyfe NFTs and $PIXA tokens are not currency and do not convey ownership in PixaLyfe or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in PixaLyfe, future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your PixaLyfe NFT. There is no guarantee that the PixaVerse platform will actually be developed, although we guarantee that it is our intention to do so.</h5>
      <br></br>  
      <br></br>  
      <br></br>  

    </div>
  );
};

export default Minter;
