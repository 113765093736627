require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 

const contractABI = require('../contract-abi.json')
const contractAddress = "0xbD16C356e4dea7b0bB821b853F684ddEB8Dd1620";

const rewardscontractABI = require('../contract-abi-rewards.json')
const rewardscontractAddress = "0x132e570dcEa4863da83FEAE9F0a7Bd47a43a45B3";

// import abis to call from other contracts. Need this for 
const wizardcontractABI = require('../contract-abi-pixawizards.json')
const wizardcontractAddress = "0xc6b0B290176AaaB958441Dcb0c64ad057cBc39a0";
const wyverncontractABI = require('../contract-abi-pixawyverns.json')
const wyverncontractAddress = "0xb144ec7d231ddde69d8790e5a868a64572a0845b";
const pixalecontractABI = require('../contract-abi-pixale.json')
const pixalecontractAddress = "0x6B6B77c3ac72C76Fb7F57444782869dE427A51b5";
const relicscontractABI = require('../contract-abi-relics.json')
const relicscontractAddress = "0x0f1255d9e644195cc6a040adfe7efe2fa7d64fce";
const wizardaocontractABI = require('../contract-abi-wizardao.json')
const wizardaocontractAddress = "0xbd16c356e4dea7b0bb821b853f684ddeb8dd1620";
const witchescontractABI = require('../contract-abi-witches.json')
const witchescontractAddress = "0xD8ab2B8b8A6036DcB91D44cbDf6e9a42a199EBE2";



export const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

export const getCurrentWalletConnected = async () => {



  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Press mint button",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

//export const checkWizards = async(name) => {
//set up your Ethereum transaction
//  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
//  let wazaaa = await window.contract.methods.wizlist.call(2,function(err, res){
//  }).encodeABI();
  
//make call to NFT smart contract 

//  return {status: wazaaa}
//}



export const executeburnrate = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.wizlist(name).call();
  
  if (wazaaa == true) {
    var result = "Claimed";
  } 
  else {
    var result = "Unclaimed";
  }
//make call to NFT smart contract 

  return {wizstatus: result}
}


export const executerewardrate = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var wazaaa = await window.contract.methods.wizlist(name).call();
  
  if (wazaaa == true) {
    var result = "Claimed";
  } 
  else {
    var result = "Unclaimed";
  }
//make call to NFT smart contract 

  return {wizstatus: result}
}



export const checktimetorewards = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var unix_timestamp = await window.contract.methods.newRewardsTimeFrame().call();
  
//make call to NFT smart contract 
  var date = new Date(unix_timestamp * 1000);
// Hours part from the timestamp
  var hours = date.getHours();
// Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
// Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

// Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

  return {timetorewards: date.toLocaleDateString()}
}




export const checktimetoburn = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var unix_timestamp = await window.contract.methods.newTimeFrame().call();
  
//make call to NFT smart contract 
  var date = new Date(unix_timestamp * 1000);
// Hours part from the timestamp
  var hours = date.getHours();
// Minutes part from the timestamp
  var minutes = "0" + date.getMinutes();
// Seconds part from the timestamp
  var seconds = "0" + date.getSeconds();

// Will display time in 10:30:23 format
  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

  return {timetoburn: date.toLocaleDateString()}
}




export const checkcurrentburnrate = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.burnAmount().call();
  
//make call to NFT smart contract 

  return {currentburnrate: result}
}


export const checkcurrentrewardrate = async(name) => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.rewardAmount().call();
  
//make call to NFT smart contract 

  return {currentrewardrate: result}
}




export const checkcurrentpixavotereason = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixareason().call();
  
//make call to NFT smart contract 

  return {currentpixavotereason: result}
}

export const checkcurrentpixavoteamount = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixaamount().call();
  
//make call to NFT smart contract 

  return {currentpixavoteamount: result}
}

export const checkcurrentpixavoteaddress= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixarecipient().call();
  
//make call to NFT smart contract 

  return {currentpixavoteaddress: result}
}

export const checkcurrentpixavotestate= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixavotingopen().call();
  
//make call to NFT smart contract 

  return {currentpixavoteaddress: result}
}

export const checkcurrentpixavote= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixavotecount().call();
  
//make call to NFT smart contract 

  return {currentpixavote: result}
}


export const checkcurrentpixatabs= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixavoteamount().call();
  var finalresult = +result - 10000;
//make call to NFT smart contract 

  return {currentpixavotetabs: finalresult}
}




export const checkcurrentethvotereason = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.ethreason().call();
  
//make call to NFT smart contract 

  return {currentethvotereason: result}
}

export const checkcurrentethvoteamount = async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.ethamount().call();
  
//make call to NFT smart contract 

  return {currentethvoteamount: result}
}

export const checkcurrentethvoteaddress= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.pixarecipient().call();
  
//make call to NFT smart contract 

  return {currentethvoteaddress: result}
}

export const checkcurrentethvotestate= async() => {
//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  var result = await window.contract.methods.ethvotingopen().call();
  
//make call to NFT smart contract 

  return {currentethvoteaddress: result}
}



export const createEthVote = async (recipient, amount, reason) => {

  try {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          value: parseInt(web3.utils.toWei("0.1","ether")).toString(16),
          'data': window.contract.methods.proposeTransferEth(recipient, amount, reason).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  } catch {}

};


export const createPixaVote = async (recipient, amount, reason) => {

  try {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          value: parseInt(web3.utils.toWei("0.1","ether")).toString(16),
          'data': window.contract.methods.proposeTransferPixa(recipient, amount, reason).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  } catch{}
};




export const pixadecline = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferPixa(true).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const pixaapprove = async () => {

  if (checkcurrentpixavotestate()) {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferPixa(false).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }
  }
 else {}

};





export const ethapprove = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferEth(true).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const ethdecline = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.votingTransferEth(false).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};


export const executeBurn = async (number) => {

  if (number >= 0 && number <21) {

    if (number == 0) {
      number = 1;
    }
    

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.burnVoting(number).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  }
  else {
  }

};


export const executeReward = async (number) => {

  if (number >= 0 && number <21) {

    if (number == 0) {
      number = 1;
    }
    

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.rewardsVoting(number).encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

  }
  else {
  }

};





export const initiateBurn = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.weeklyBurn().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};




export const initiateRewards = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.weeklyRewards().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};


export const finalizeEth = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.initiateTransferEth().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const finalizePixa = async () => {


    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const transactionParameters = {
          to: contractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.initiateTransferPixa().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }

};



export const claimNFTRewards = async () => {

    window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
    const transactionParameters = {
          to: rewardscontractAddress, // Required except during contract publications.
          from: window.ethereum.selectedAddress, // must match user's active address.
          'data': window.contract.methods.claimRewards().encodeABI()//make call to NFT smart contract 
    }

    try {
    const txHash = await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        });
    return {
        success: true,
    }
  } catch (error) {
    return {
        success: false,
    }

 }


};



export const calculateCurrentRewards= async(addressowner) => {
//var addressowner = rewardsaddress; //"0x7cA6B4645B71f874A35Bec18F0e997E51D8d815C"

//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);
  window.contract2 = await new web3.eth.Contract(wizardcontractABI, wizardcontractAddress);
  window.contract3 = await new web3.eth.Contract(wyverncontractABI, wyverncontractAddress);
  window.contract4 = await new web3.eth.Contract(pixalecontractABI, pixalecontractAddress);
  window.contract5 = await new web3.eth.Contract(relicscontractABI, relicscontractAddress);
  window.contract6 = await new web3.eth.Contract(wizardaocontractABI, wizardaocontractAddress);
  window.contract7 = await new web3.eth.Contract(witchescontractABI, witchescontractAddress);

  var result1 = await window.contract2.methods.balanceOf(addressowner).call();
  var result2 = await window.contract3.methods.balanceOf(addressowner).call();
  var result3 = await window.contract4.methods.balanceOf(addressowner).call();
  var result4 = await window.contract5.methods.balanceOf(addressowner).call();
  var result5 = await window.contract6.methods.balanceOf(addressowner).call();
  var result6 = await window.contract7.methods.balanceOf(addressowner).call();

  var array1 = [];
  var array2 = [];
  var array3 = [];
  var array4 = [];
  var array5 = [];
  var array6 = [];

  // get all token IDs that the owner has
  for (let i = 0; i < result1; i++) {
     var tempnum1 = await window.contract2.methods.tokenOfOwnerByIndex(addressowner, i).call();
     array1.push(tempnum1)
  }
  for (let i = 0; i < result2; i++) {
     var tempnum2 = await window.contract3.methods.tokenOfOwnerByIndex(addressowner, i).call();
     array2.push(tempnum2)
  }
  for (let i = 0; i < result3; i++) {
     var tempnum3 = await window.contract4.methods.tokenOfOwnerByIndex(addressowner, i).call();
     array3.push(tempnum3)
  }
  for (let i = 0; i < result4; i++) {
     var tempnum4 = await window.contract5.methods.tokenOfOwnerByIndex(addressowner, i).call();
     array4.push(tempnum4)
  }
  for (let i = 0; i < result5; i++) {
     var tempnum5 = await window.contract6.methods.tokenOfOwnerByIndex(addressowner, i).call();
     array5.push(tempnum5)
  }
  for (let i = 0; i < result6; i++) {
     var tempnum6 = await window.contract7.methods.tokenOfOwnerByIndex(addressowner, i).call();
     array6.push(tempnum6)
  }

  var currentTime = 0.001*Date.now()
  var amount = 0;

  for (let i = 0; i < result1; i++) {
     var tempnum1 = await window.contract.methods.NFTtime(0, array1[i]).call();
      if (parseInt(tempnum1) > currentTime) {
      amount = amount;
      }
      if (parseInt(tempnum1)< currentTime) {
      amount = 30 + amount;
      }
      if (parseInt(tempnum1) + 604800 < currentTime) {
      amount = 30 + amount;
      }
      if (parseInt(tempnum1) + 1209600 < currentTime) {
      amount = 30 + amount;
      }
      if (parseInt(tempnum1) + 1814400 < currentTime) {
      amount = 30 + amount;
      }
  }

  var amount1 = amount;
  for (let i = 0; i < result2; i++) {
     var tempnum2 = await window.contract.methods.NFTtime(1, array2[i]).call();
      if (parseInt(tempnum2) > currentTime) {
      amount = amount;
      }
      if (parseInt(tempnum2) < currentTime) {
      amount = 20 + amount;
      }
      if (parseInt(tempnum2) + 604800 < currentTime) {
      amount = 20 + amount;
      }
      if (parseInt(tempnum2) + 1209600 < currentTime) {
      amount = 20 + amount;
      }
      if (parseInt(tempnum2) + 1814400 < currentTime) {
      amount = 20 + amount;
      }
  }
  var amount1 = amount;
  for (let i = 0; i < result3; i++) {
     var tempnum4 = await window.contract.methods.NFTtime(3, array3[i]).call();
      if (parseInt(tempnum4) > currentTime) {
      amount = amount;
      }
      if (parseInt(tempnum4) < currentTime) {
      amount = 10 + amount;
      }
      if (parseInt(tempnum4) + 604800 < currentTime) {
      amount = 10 + amount;
      }
      if (parseInt(tempnum4) + 1209600 < currentTime) {
      amount = 10 + amount;
      }
      if (parseInt(tempnum4) + 1814400 < currentTime) {
      amount = 10 + amount;
      }
  }
  var amount1 = amount;

  for (let i = 0; i < result4; i++) {
     var tempnum4 = await window.contract.methods.NFTtime(4, array4[i]).call();
      if (parseInt(tempnum4) > currentTime) {
      amount = amount;
      }
      if (parseInt(tempnum4) < currentTime) {
      amount = 2 + amount;
      }
      if (parseInt(tempnum4) + 604800 < currentTime) {
      amount = 2 + amount;
      }
      if (parseInt(tempnum4) + 1209600 < currentTime) {
      amount = 2 + amount;
      }
      if (parseInt(tempnum4) + 1814400 < currentTime) {
      amount = 2 + amount;
      }
  }
  var amount1 = amount;

  for (let i = 0; i < result5; i++) {
     var tempnum5 = await window.contract.methods.NFTtime(2, array5[i]).call();
      if (parseInt(tempnum5) > currentTime) {
      amount = amount;
      }
      if (parseInt(tempnum5) < currentTime) {
      amount = 15 + amount;
      }
      if (parseInt(tempnum5) + 604800 < currentTime) {
      amount = 15 + amount;
      }
      if (parseInt(tempnum5) + 1209600 < currentTime) {
      amount = 15 + amount;
      }
      if (parseInt(tempnum5) + 1814400 < currentTime) {
      amount = 15 + amount;
      }
  }
  var amount1 = amount;

  for (let i = 0; i < result6; i++) {
     var tempnum6 = await window.contract.methods.NFTtime(5, array6[i]).call();
      if (parseInt(tempnum6) > currentTime) {
      amount = amount;
      }
      if (parseInt(tempnum6) < currentTime) {
      amount = 15 + amount;
      }
      if (parseInt(tempnum6) + 604800 < currentTime) {
      amount = 15 + amount;
      }
      if (parseInt(tempnum6) + 1209600 < currentTime) {
      amount = 15 + amount;
      }
      if (parseInt(tempnum6) + 1814400 < currentTime) {
      amount = 15 + amount;
      }

  }


  amount = amount;


  return {totalrewardsavailable: amount}
}




export const checkSpecificRewards= async(speccontract, tknid) => {
//var addressowner = rewardsaddress; //"0x7cA6B4645B71f874A35Bec18F0e997E51D8d815C"

//set up your Ethereum transaction
  window.contract = await new web3.eth.Contract(rewardscontractABI, rewardscontractAddress);

  if (speccontract == "select") {
    amount = " Select Collection "
  } else {

  if (speccontract == "wizards") {
  var result1 = await window.contract.methods.NFTtime(0,tknid).call();
  var reward = 30
  }
  if (speccontract == "witches") {
  var result1 = await window.contract.methods.NFTtime(5,tknid).call();
  var reward = 15
  }
  if (speccontract == "pixale") {
  var result1 = await window.contract.methods.NFTtime(3,tknid).call();
  var reward = 10
  }
  if (speccontract == "wyverns") {
  var result1 = await window.contract.methods.NFTtime(1,tknid).call();
  var reward = 20
  }
  if (speccontract == "relics") {
  var result1 = await window.contract.methods.NFTtime(4,tknid).call();
  var reward = 2
  }
  if (speccontract == "wizardao") {
  var result1 = await window.contract.methods.NFTtime(2,tknid).call();
  var reward = 15
  }
  var currentTime = 0.001*Date.now()
  var amount = 0

  if (parseInt(result1) > currentTime) {
    amount = amount;
  }
  if (parseInt(result1) < currentTime) {
    amount = reward + amount;
  }
  if (parseInt(result1) + 604800 < currentTime) {
    amount = reward + amount;
  }
  if (parseInt(result1) + 1209600 < currentTime) {
    amount = reward + amount;
  }
  if (parseInt(result1) + 1814400 < currentTime) {
    amount = reward + amount;
  }


  }
  

  return {specificrewardsavailable: amount}
}

